import React from 'react'
import FaviconIcon from '../images/about_shape.png'
import WordpressServiceBanner from '../images/wordpress_service_banner.svg'
import WordpressServiceFeature from '../images/wordpress_service_features.svg'
import AndroidServiceShapeone from '../images/service_banner_shape.png'
import AndroidServiceShapeTwo from '../images/service_banner_shape_2.png'
import ServiceCircleShape from '../images/half_circle_service_page.png'
import Layout from '../components/layout'
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import 'lazysizes'

const WordpressDevelopment = () => {
  return (
    <Layout>
        <Helmet>
          <meta name="keywords" content="web development, ecommerce development, mobile app development" />
          <link rel="canonical" href="https://ewisesolutions.com/wordpress-web-development-services" />
          <link rel="icon" href={FaviconIcon} />
        </Helmet>
		<Seo title="Wordpress Web Development Services" description='eWise - Wordpress Web Development Services'/>
 <section className="relative desktop_version pt-24 pb-24 lg:pt-24 lg:pb-24 xl:pt-24 xl:pb-24 2xl:pt-24 2xl:pb-24">
        <div className="container">
            <div className="-mx-4">
                <div className="banner_section flex flex-wrap items-center min-h-82 sm:min-h-82 md:min-h-82 lg:min-h-93 xl:min-h-97 2xl:min-h-97  flex-col lg:flex-row justify-center">
                    <div className="order-2 sm:order-1 left_banner w-full lg:w-1/2 px-4">
                        <div className="inner text-center lg:text-left">
                            <div className="">
                                <h1 className=" font-normal relative"><strong className="text-50 md:text-55 lg:text-6xl xl:text-70 2xl:text-90 block">Wordpress</strong> <span className="relative top-m-10 md:top-m-10 lg:top-m-15 2xl:top-m-37 inline-block md:pl-0 xl:pl-10 2xl:pl-14 text-gray text-2xl md:text-3xl lg:text-32 xl:text-34 2xl:text-46">Web Development Services</span> <span className="hidden xl:inline-block xl:left-m-32 2xl:left-m-40 xl:top-m15 2xl:top-m32 circle bg-blue absolute rounded-full z-1 w-10 h-10 sm:w-10 sm:h-10 md:w-10 md:h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-74 2xl:h-74"></span></h1>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10 lg:mt-0 order-2 sm:order-2 right_banner w-full lg:w-1/2 px-4 sm:pt-0">
                        <div className="inner relative xl:p-12 2xl:p-0">
                            <img className="w-full sm:w-auto max-w-350 md:max-w-500 lg:max-w-530 m-auto lazyload" loading="lazy" data-src={WordpressServiceBanner} alt="Banner"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <img className="absolute right-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeone} alt="Wordpress"/>
        <img className="absolute left-0 top-m112 xl:top-0 z-1 max-w-120 md:max-w-150 lg:max-w-180 xl:max-w-220 2xl:max-w-full" src={AndroidServiceShapeTwo} alt="Wordpress"/>
    </section>
  <section className="relative">
      <div className="container">
          <div className="-mx-4">
            <div className="text_content pb-10 md:pb-12 lg:pb-16 xl:pb-24 px-4 xl:pr-16 2xl:pr-32">
                <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-35 2xl:text-40 ">Get everything you need to build an engaging website - that too FAST!<span className="ml-6  hidden xl:inline-block w-m100 bg-pink h-0.8 mb-2"></span></h4>
                <p>A window to your brand; now create the lasting impression on your visitors and engage them with an interactive website design with the power of WordPress! A stunning website is just a few clicks away with WordPress. The outstanding features of this platform help you to build every kind of the website for every kind of business.</p>
            </div>

          </div>
      </div>
  </section>
  <section className="relative pb-8 sm:pb-10 lg:pb-14">
      <div className="container">
          <div className="-mx-4">
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="features_left w-full lg:w-1/2 px-4 order-2 lg:order-1">
                    <h4 className="pb-4 xl:pb-6 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">The stunning features of <br class="hidden xl:inline-block"></br> WordPress<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Create robust website for any business</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">There is a plan for every budget</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Get the custom domain to create your personalized web space</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Dozens of themes available to choose from</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Mobile-friendly themes easily available for responsiveness</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">A fast and intuitive editor for ease modification</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Drag and drop feature to embed media on your website</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Your website can be edited from any device, anytime</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Grow your visibility with a built-in social media sharing feature</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Analyze your website in colorful and easy-to-read charts</li>
                        <li className="pl-10 relative">Rank better with the SEO friendly structure of the platform</li>
                    </ul>
                </div>
                <div className="features_right w-full lg:w-1/2 flex justify-center px-4 order-1 lg:order-2">
                    <img className="object-contain max-w-330 md:max-w-400 xl:max-w-530 mb-5 lg:mb-0 w-full lazyload" loading="lazy" data-src={WordpressServiceFeature} alt="Wordpress"/>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">eWise Services for you!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full px-4 pb-0">
                    <h5 className="pb-4 text-lg md:text-xl lg:text-22">Easily Share your thoughts through our blog services with eWise</h5>
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Optimized for growth with a built‑in SEO, social media integration, and sharing features</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">All-in-one hosting includes website design, domain registration, worry-free automatic software updates, and secure hosting on mirrored servers</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Get the support you need through live chat, email, support pages, videos, and forums to answer any questions you have</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Design a creative site design and customize it with your branding, content, and features</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Get the real-time insights of your blog’s activity with website statistics through easy and colorful charts to read</li>
                        <li className="pl-10 relative">Ready-made responsive themes and mobile and desktop apps for a seamless experience on any device</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Create the website of your dream with eWise<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full px-4 pb-0">
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Get a plan for any budget from free to premium to access advanced customization design features</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Create a website with blogs! Why to choose when you can have both at same cost</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Intuitive editor helps in faster development of a website and also includes HTML and Markdown support.</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Use a simple drag‑and‑drop feature to embed images into posts and pages, embed audio, video, documents, and more</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Jetpack essential features including site statistics, basic SEO, and social media sharing to expand and track the growth</li>
                        <li className="pl-10 relative">Manage and modify your site from anywhere with mobile and desktop apps</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Choose the right domain with eWise<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full px-4 pb-0">
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Choose a domain name that is unique and quick to remember! WordPress enables you to activate email forwarding or connect to G Suite to add a personal email address for your brand.</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Every domain selected has a placeholder for the website! You can start to customize it with your brand and business elements whenever you want to.</li>
                        <li className="pl-10 relative">Get your website ready as quickly as possible! The simple and intuitive UI of WordPress enables you to get your work done quickly without much support from elsewhere.</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap  pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Set up a full-featured eCommerce store with eWise<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                </div>
                <div className="features_list_left w-full px-4 pb-0">
                    <ul className="list_features">
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Start accepting the payments from anyone, anywhere, anytime! You can enable payments through debit or credit cards without any hassle for you or your customer through WordPress website.</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Get all the tools you need to publish content and sell products through a website. We can create a stunning online store and integrate more plugins to help you customize the platform, engage customers, and increase your business.</li>
                        <li className="mb-3 md:mb-3 lg:mb-3 xl:mb-3 2xl:mb-4 pl-10 relative">Create an income stream with recurring payments with your premium content! Automate it to be delivered to your followers’ inboxes as a paid service/newsletter.</li>
                        <li className="pl-10 relative">Set up subscriptions with automated payments and no third-party billing. The subscribers can cancel the subscription anytime they want.</li>
                    </ul>
                </div>
            </div>
            <div className="features_section flex flex-wrap pb-8 sm:pb-10 lg:pb-14">
                <div className="text_content w-full">
                    <h4 className="pb-4 xl:pb-6 px-4 text-2xl sm:text-26 lg:text-28 xl:text-3xl font-medium">Why hire eWise as a WordPress expert!<span className="hidden xl:inline-block ml-6  w-m100 bg-pink h-0.8 mb-2"></span></h4>
                    <p className="px-4 pb-4">WordPress is a stunning platform that helps every business to build their website just as unique as they are. The themes and features together make it viable for various needs. And, the budget plans it offers are unbeatable! Choosing WordPress as your website platform? Then hire an expert to make your website a success!</p>
                    <p className="px-4 pb-4">eWise has an extremely professional and efficient team that is well versed with latest technologies and their every feature and technique. We ensure that your website development using WordPress is completely unique and is in line with your brand. We strive to equip your website with functionalities, which would help your users to efficiently use them.</p>
                    <p className="px-4">We promise to deliver the best website project in both your timeline and budget!</p>
                </div>
            </div>
          </div>
      </div>
      <img className="absolute right-0 bottom-1/4 z-1 max-w-150 md:max-w-180 lg:max-w-200 xl:max-w-250 2xl:max-w-320" src={ServiceCircleShape} alt="Wordpress Circle"/>
  </section>
    </Layout>
  )
}

export default WordpressDevelopment